import axios from 'axios';

const url = 'https://api.fireflies.ai/graphql';

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${process.env.REACT_APP_FIREFLIES}`,
};

export const handleUploadForTranscript = async (videoUrl) => {
  const input = {
    url: videoUrl,
    title: 'title of the file',
    attendees: [
      {
        displayName: 'assistant',
        email: 'assistant@bluebash.co',
        phoneNumber: '9876543210',
      },
      {
        displayName: 'Mayank',
        email: 'mayank.gupta@bluebash.co',
        phoneNumber: '8708726668',
      },
    ],
  };
  const data = {
    query: `       mutation($input: AudioUploadInput) {
          uploadAudio(input: $input) {
            success
            title
            message
          }
        }
      `,
    variables: { input },
  };

  const response = await axios.post(url, data, { headers: headers });
  return response;
};
