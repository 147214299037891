import React, { useEffect, useState } from 'react';

const SoundBar = ({ isListening }) => {
  const [bars, setBars] = useState(new Array(5).fill(0));

  useEffect(() => {
    let interval;
    if (isListening) {
      interval = setInterval(() => {
        setBars(bars.map(() => Math.random() * 100));
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isListening, bars]);

  return (
    <div className="sound-bar-container">
      {bars.map((height, index) => (
        <div key={index} className="sound-bar" style={{ height: `${height}%` }}></div>
      ))}
    </div>
  );
};

export default SoundBar;
