import React, { useEffect, useState } from 'react';
import * as sdk from '@d-id/client-sdk';
import './App.css';
import { startRecording, stopRecoding } from './utils/recorder';
import { listObjects, uploadVideoToS3 } from './utils/s3';
import { handleUploadForTranscript } from './utils/transcript';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import SoundBar from './components/SoundBar';

export default function App() {
  const [agentManager, setAgentManager] = useState(null);
  const [userQuestion, setUserQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [s3Objects, setS3Objects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const auth = { type: 'key', clientKey: process.env.REACT_APP_CLIENTKEY };
      const callbacks = {
        onSrcObjectReady: (value) => {
          const videoElement = document.getElementById('videoElement');
          videoElement.srcObject = value;
        },
        onVideoStateChange: (state) => {
          if (state === 'STOP') {
            SpeechRecognition.startListening();
          }
          console.log('onVideoStateChange:', state);
        },
        onConnectionStateChange: (state) => {
          console.log('onConnectionStateChange:', state);
          if (state === 'connected') {
            setIsConnected(true);
          } else {
            setIsConnected(false);
          }
        },
        onNewMessage: (newMessages, type) => {
          console.log(newMessages, type);
          setMessages(newMessages);
        },
        onError: (error, errorData) => {
          console.error('Error:', error, 'Error Data:', errorData);
        },
      };
      const streamOptions = { compatibilityMode: 'auto', streamWarmup: true };
      const manager = await sdk.createAgentManager(process.env.REACT_APP_AGENTID, { auth, callbacks, streamOptions });
      setAgentManager(manager);
    })();

    (async () => {
      const response = await listObjects();
      setS3Objects(response);
    })();
  }, []);

  const handleChat = () => {
    if (userQuestion.trim() !== '') {
      agentManager.chat(userQuestion);
      setUserQuestion('');
    }
  };

  const handleStartChat = async () => {
    setLoading(true);
    await agentManager.connect();
    // startRecording();
    setLoading(false);
  };

  const flashErrorMessage = (msg) => {
    toast.error(msg);
  };

  const handleEndChat = async () => {
    await agentManager.disconnect();
    // const blob = await stopRecoding();
    // const uploadedEndPoint = await uploadVideoToS3(blob);
    // const res = await handleUploadForTranscript(uploadedEndPoint);
    // setData(res.data);
    // if (res.data.errors) {
    //   flashErrorMessage(res.data.errors[0].message);
    // }
    // console.log(res);
  };

  const uploadVideoForTransript = async (link) => {
    const res = await handleUploadForTranscript(link);
    setData(res.data);
    if (res.data.errors) {
      flashErrorMessage(res.data.errors[0].message);
    }
    console.log(res);
  };

  const { transcript, listening } = useSpeechRecognition();

  useEffect(() => {
    if (transcript.trim() !== '' && agentManager && listening === false) {
      agentManager.chat(transcript);
    }
  }, [listening, agentManager, transcript]);

  useEffect(() => {
    if (agentManager && isConnected) {
      agentManager.speak({
        type: 'text',
        input: "Hi! I'm sofia. How can I help you?",
      });
    }
  }, [agentManager, isConnected]);

  return (
    <div className='app-container'>
      <div className='video-container'>
        <video
          id='videoElement'
          autoPlay
          width={600}
          height={600}
        />
        {/* <div className='chat-history'>
          {messages.map((message, index) => (
            <p
              key={index}
              className={message.role}>
              {message.content}
            </p>
          ))}
        </div> */}
      </div>
      <div className='control-buttons'>
        {!isConnected && (
          <button
            onClick={handleStartChat}
            disabled={loading || !agentManager}>
            {loading ? 'Staring...' : 'Start Chat'}
          </button>
        )}
        {isConnected && <button onClick={handleEndChat}>End Chat</button>}
      </div>
      <SoundBar isListening={listening} />
      {/* {listening? 'listing...' : ''} */}
      {/* <div className='chat-container'>
        <input
          type='text'
          value={userQuestion}
          onChange={(e) => setUserQuestion(e.target.value)}
          placeholder='Ask a question...'
          disabled={!isConnected}
        />
        <button
          onClick={handleChat}
          disabled={!isConnected}>
          Send
        </button>
        <p>Microphone: {listening ? 'on' : 'off'}</p>
        <button onClick={SpeechRecognition.startListening}>Start</button>
        <button onClick={SpeechRecognition.stopListening}>Stop</button>
        <button onClick={resetTranscript}>Reset</button>
        <p>{transcript}</p>
      </div> */}
      {/* <div className='s3-list-container'>
        <div className='s3-list'>
          <h2>S3 Objects:</h2>
          <ul>
            {s3Objects.map((item, index) => (
              <li key={index}>
                <p>{item.Key.split('.mp4')[0]}</p>
                <button onClick={() => uploadVideoForTransript(item.url)}>Send to Fireflies</button>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
      <ToastContainer />
    </div>
  );
}
