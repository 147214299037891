import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: process.env.REACT_APP_REGION,
  s3ForcePathStyle: true,
});

const s3 = new AWS.S3();

export const uploadVideoToS3 = async (blob) => {
  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: `recording-${Date.now()}.mp4`,
    Body: blob,
    ContentType: 'video/mp4',
  };
  try {
    const upload = await s3
      .upload(params)
      .promise();
    console.log('Upload successful:', upload.Location);
    return upload.Location;
  } catch (error) {
    console.error('Upload error:', error);
  }
};

export const listObjects = async () => {
  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const objectsWithUrls = data.Contents.map(object => {
      const url = s3.getSignedUrl('getObject', {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: object.Key,
        Expires: 60 * 60,
      });
      return { ...object, url };
    });
    return objectsWithUrls;
  } catch (error) {
    console.error('Error listing S3 objects:', error);
  }
};